// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$benchmarker-forms-theme: mat.define-theme(
    (
        color: (
            theme-type: light,
            primary: mat.$azure-palette,
            tertiary: mat.$blue-palette,
        ),
        density: (
            scale: 0,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
    @include mat.all-component-themes($benchmarker-forms-theme);

    // --border-color: #e8e8e8;
    --border-radius: 8px;
    // --primary-color: #30c2ec;
    --secondary-color: #ec3091;
    // --text-primary-color: #212529;
    // --text-secondary-color: #666666;
    --shadow-md: 0 5px 30px -5px rgba(8, 0, 42, 0.1);
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    font-size: clamp(14px, 2vw, 16px);
    line-height: 1.5;
    position: relative;
    background: var(--theme-background);
    color: var(--theme-secondary-text);
}

.normal-fs {
    font-size: clamp(14px, 2vw, 16px);
    line-height: 1.5;
}

.title {
    color: var(--theme-primary-default);

    font-weight: 600;
    font-size: clamp(20px, 2vw, 1.5rem);
}

.sub-title {
    color: var(--theme-secondary-text);

    font-weight: 400;
    font-size: clamp(16px, 1.8vw, 1.2rem);
}

.mat-toolbar-row {
    box-shadow: 0px 3px 4px 0px #ddd;
}

*:not(.material-icons) {
    font-family: "Noto Sans Symbols", sans-serif !important;
}

[dir="rtl"] *:not(.material-icons) {
    font-family: "Noto Sans Arabic", sans-serif !important;
}

//remove scrollbar from body
body {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;
}

body::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.container {
    min-height: 100%;
    width: 100%;
    max-width: 1180px;
    display: block;
    margin: 0 auto;
    padding: 1rem;

    @media (min-width: 1180px) {
        .app.container {
            padding: 1rem 0;
        }
    }
}
.mat-form-field-appearance-fill .mat-form-field-flex {
    padding-block: 0 !important;
}
.mat-radio-label-content {
    color: var(--theme-secondary-text);
}
.mat-radio-button.mat-radio-checked .mat-radio-label-content .star {
    color: gold;
}
//remove scroll bar from mat drawer
.mat-drawer-content {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;
}

.mat-drawer-content::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.spacer {
    flex: 1 1 auto;
}

.mat-drawer-container {
    // background-color: #eee;
    width: 100%;
    height: 100%;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    // @media(max-width: 768px){
    //     min-height: 100%;
    // }
}

.mat-drawer-container::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.embed-f,
.embed-f > .tf-v1-widget:first-child {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    width: 100%;
}

.embed-f > .tf-v1-widget:first-child > iframe {
    height: 100%;
}

.dialog-wrapper-overlay {
    .mat-dialog-container {
        overflow: inherit !important;
    }
}

#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #c1185a;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.flex {
    display: flex;
}

.row {
    flex-direction: row;
}

.col {
    flex-direction: column;
}

.nowrap {
    flex-flow: nowrap;
}

.a-center {
    align-items: center;
}

.space-between {
    justify-content: space-between;
}

.primary-color {
    color: var(--primary-color);
}

.mbe-1 {
    margin-block-end: 1rem;
}

.mbe-2 {
    margin-block-end: 2rem;
}

.mbe-3 {
    margin-block-end: 3rem;
}

.mbs-1 {
    margin-block-start: 1rem;
}

.mbs-2 {
    margin-block-start: 2rem;
}

.mbs-3 {
    margin-block-start: 3rem;
}

.mis-1 {
    margin-inline-start: 1rem;
}

.swiper-horizontal > .swiper-pagination-bullets {
    bottom: 0 !important;

    .swiper-pagination-bullet {
        width: 20px;
        height: 5px;
        border-radius: 10px;
        border: 1px solid var(--theme-divider);
        background-color: var(--theme-card);
    }
    .swiper-pagination-bullet-active {
        background-color: var(--theme-primary-default);
    }
}
